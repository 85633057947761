@charset "utf-8";

html {
  font-family: $sans-serif;
  font-size: 62.5%;
  font-weight: 400;
  line-height: 1.6;
  word-wrap: break-word;
  color: $c-text;
}

body {
  @include font-size(1.5);
}

a {
  outline: none;
  text-decoration: underline;
  &:link {
    color: $c-blue;
    -moz-transition: opacity 0.15s ease-in-out;
    -o-transition: opacity 0.15s ease-in-out;
    -webkit-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;
  }
  &:visited {
    color: $c-blue;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.65;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

strong {
  font-weight: bold;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

.hover {
  transition: opacity 0.15s ease-in-out;
}
.hover:hover {
  cursor: pointer;
  opacity: 0.65;
}

/* -- inner -- */
.inner {
  width: 1050px;
  margin: 0 auto;
  @include media(pcS) {
    width: 720px;
  }
  @include media(sp) {
    width: auto;
    padding: 0 15px;
  }
}

/* -- box -- */
.flex-box {
  display: flex;
  flex-wrap : wrap;
  justify-content:space-between;
  @include media(sp) {
    display: block;
  }
}

/* -- background -- */
.bg-gray {
  background-color: $c-gray;
}

/* -- title -- */
.ttl-rounded {
  margin: 60px 0 20px 0;
  padding: 8px 10px;
  @include font-size(2.2);
  color: $c-blue;
  background-color: #fff;
  border: 2px solid $c-blue;
  border-radius: 8px;
  &:first-child {
    margin-top: 0;
  }
  @include media(sp) {
    line-height: 1.4;
  }
}
.ttl-border {
  margin: 40px 0 15px 0;
  font-weight: bold;
  @include font-size(2.0);
  color: $c-blue;
  border-bottom: 1px solid $c-blue;
}
.ttl-bold {
  font-weight: bold;
  @include font-size(1.7);
  color: $c-blue;
}
.ttl-product {
  padding: 8px 10px;
  @include font-size(2.2);
  color: #fff;
  border-radius: 8px;
  @include media(sp) {
    line-height: 1.4;
  }
}

/* -- text -- */
.txt-left {
  text-align: center;
}
.txt-center {
  text-align: center;
}
.txt-right {
  text-align: right;
}
.txt-bold {
  font-weight: bold;
}
.txt-red {
  color: #ff0000;
}

/* -- img&text -- */
.img-txt {
  overflow: hidden;
  .img-area {
    float: left;
  }
}

/* -- button -- */
.btn {
  -moz-appearance: none;
  -webkit-appearance: none;
  text-align: center;
  border: none;
  a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
  }
}
.btn-link {
  width: 250px;
  @include media(sp) {
    margin-right: auto;
    margin-left: auto;
  }
  a {
    padding: 10px;
    @include font-size(1.7);
    border-radius: 30px;
    color: #fff;
    background-color: $c-blue;
    box-sizing: border-box;
  }
}
.btn-back {
  max-width: 220px;
  margin-top: 60px;
}

/* -- form-control -- */
.form-control {
  display: inline-block;
  padding: 0.65rem 0.75rem;
  line-height: 1.25;
  @include font-size(1.5);
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  background-color: #fff;
  border-color: #55a6f8;
  outline: none;
}
.form-control::placeholder {
  color: #868e96;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
select.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff url(../img/icon_down.svg) no-repeat;
  background-size: 18px auto;
  background-position: right 0.15em center;
}
select.form-control::-ms-expand {
  display: none;
}

/* -- list -- */
.num-list {
  list-style-type: decimal;
  padding-left: 20px;
}
.disc-list {
  li {
    position: relative;
    padding-left: 1em;
    &:before {
      display: block;
      content: "・";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.menu-list {
  li {
    position: relative;
    margin: 0 0 8px;
    padding-left: 12px;
    &:before {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      content: '';
      top: 7px;
      left: 0;
      border-style: solid;
      border-width: 5px 0 5px 8px;
      border-color: transparent transparent transparent #aaa;
    }
    a {
      font-weight: bold;
      color: $c-text;
    }
  }
  li > .menu-list {
    margin-top: 5px;
    margin-bottom: 0px;
    li {
      position: relative;
      padding-left: 1em;
      &:before {
        display: block;
        content: "・";
        position: absolute;
        top: 0;
        left: 0;
        border: none;
        background-color: transparent;
      }
    }
  }
}

/* -- pager -- */
.pager {
  margin-top: 40px;
  text-align: center;
  a,span {
    display: inline-block;
    line-height: 1.0;
  }
  a,.current_page {
    margin: 2px;
    padding: 8px 12px;
    text-decoration: none;
    border: 1px solid #cecece;
    color: $c-text;
  }
  .current_page {
    background-color: #ffffcc;
  }
}

/* -- margin -- */
.mt-0 { margin-top: 0; }
.mt-5 { margin-top: 5px; }
.mt-15 { margin-top: 10px; }
.mt-20 { margin-top: 20px; }
.mt-25 { margin-top: 25px; }
.mt-30 { margin-top: 30px; }
.mt-35 { margin-top: 35px; }
.mt-40 { margin-top: 40px; }
.mt-45 { margin-top: 45px; }
.mt-50 { margin-top: 50px; }

/* -- main-contents -- */
#main-contents {
  width: 720px;
  @include media(pc) {
    float: left;
  }
  @include media(sp) {
    width: auto;
  }
}

/* -- side-contents -- */
#side-contents {
  padding: 20px 15px;
  background-color: #ddebf8;
  @include media(pc) {
    float: right;
    width: 240px;
  }
  @include media(pcS) {
    margin-top: 60px;
  }
  @include media(sp) {
    width: auto;
    margin-top: 60px;
  }
  .ttl-rounded {
    margin: 0 0 15px 0;
    font-weight: bold;
    @include font-size(1.7);
    &:first-child {
      margin-top: 0;
    }
  }
  .menu-list {
    margin-bottom: 30px;
    li {
      margin-bottom: 5px;
      a {
        font-weight: normal;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    li > .menu-list {
      margin-bottom: 0px;
    }  
  }
  .btn-link {
    width: auto;
    max-width: 250px;
    margin: 0 auto;
  }
}

/* -- spmenu- -- */
.spmenu-btn {
  display: block;
  position: absolute;
  top: 21px;
  right: 15px;
  width: 26px;
  height: 22px;
  z-index: 10;
  background: transparent;
  span {
    display: block;
    position: absolute;
    top: 10px;
    width: 26px;
    height: 2px;
    background: $c-blue;
  }
  @include media(pc) {
    display: none;
  }
  @include media(pcS) {
    right: 0;
  }
}
.spmenu-btn::before {
  content:'';
  position: absolute;
  width: 26px;
  height: 2px;
  background: $c-blue;
  top: 0;
  right: 0;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.spmenu-btn::after {
  content:'';
  position: absolute;
  width: 26px;
  height: 2px;
  background: $c-blue;
  bottom: 0;
  right: 0;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.spmenu-btn.active {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: transparent;
  border-top: 10px solid rgba(255,255,255,0);
  border-bottom: 10px solid rgba(255,255,255,0);
  span {
    display: none;
  }
}
.spmenu-btn.active::before {
  -moz-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  top: 0;
  right: 0;
  background: $c-blue;
}
.spmenu-btn.active:after {
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  bottom: 0;
  right: 0;
  background: $c-blue;
}

#spmenu {
  display: none;
  position: absolute;
  top: 0;
  right: -350px;
  z-index: 5;
  min-width: 300px;
  height: 100vh;
  padding: 100px 20px 0;
  background-color: #ddebf8;
  box-sizing: border-box;
  @media only screen and (max-width: 349px) {
    min-width: 280px;
  }
  @include media(pcS) {
    width: 350px;
  }
  .ttl-rounded {
    margin: 0 0 15px 0;
    font-weight: bold;
    @include font-size(1.7);
  }
  .menu-list {
    li {
      a {
        font-weight: normal;
        text-decoration: none;
      }
    }
  }
}

.bg-spmenu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index:3;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background-color: #000;
}

/* -- header -- */
#header {
  border-bottom: 1px solid $c-border;
  .inner {
    position: relative;
  }
  .logo {
    padding: 4px 0;
    background-color: $c-blue;
    span {
      display: block;
      color: #fff;
      @include font-size(1.5);
    }
  }
  .ttl {
    padding-top: 10px;
    padding-bottom: 10px;
    color: $c-blue;
    @include font-size(2.8);
  }
}

/* -- footer -- */
#footer {
  margin-top: 40px;
  @include media(sp) {
    margin-top: 20px;
  }
  .fmenu-area {
    overflow: hidden;
    padding: 20px 0;
    background-color: #eee;
  }
  .fmenu {
    float: left;
    margin-right: 50px;
    @include media(sp) {
      float: none;
      margin-right: 0;
    }
    li {
      position: relative;
      margin: 5px 0;
      padding-left: 12px;
      &:before {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        content: '';
        top: 7px;
        left: 0;
        border-style: solid;
        border-width: 5px 0 5px 8px;
        border-color: transparent transparent transparent #aaa;
      }
      a {
        color: $c-text;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .copyright {
    padding: 4px 0;
    text-align: center;
    color: #fff;
    background-color: $c-blue;
    @include font-size(1.3);
    span {
      display: block;
    }
  }
}

/* -- breadcrumbs -- */
.breadcrumbs {
  padding: 20px 0;
  li {
    display: inline-block;
  }
}

/* -- live-chat -- */
.btn-live-chat {
  padding: 10px;
  text-align: right;
  img {
    @include media(pcS) {
      width: 89px;
    }
    @include media(sp) {
      width: 79px;
      padding: 5px;
    }
  }
}

/* -- 製品から探す -- */
#search-product {
  .product-box {
    border: 1px solid $c-border;
    box-sizing: border-box;
    .ttl {
      padding: 15px 10px 15px 57px;
      font-weight: bold;
      @include font-size(1.8);
      border-top: 10px solid $c-blue;
      background-repeat: no-repeat;
      background-position: 15px center;
      background-image: url("../img/icon_product06.png");
      background-size: 32px auto;
    }
    .menu-list {
      padding: 0 15px 10px;
    }
    &.data-recover {
      .ttl {
        color: #ff0000;
        border-color: #ff0000;
        background-image: url("../img/icon_product01.png");
      }
    }
    &.data-terminator {
      .ttl {
        color: #0070c0;
        border-color: #0070c0;
        background-image: url("../img/icon_product02.png");
      }
    }
    &.data-move {
      .ttl {
        color: #00b050;
        border-color: #00b050;
        background-image: url("../img/icon_product03.png");
      }
    }
    &.data-backup {
      .ttl {
        color: #002060;
        border-color: #002060;
        background-image: url("../img/icon_product04.png");
      }
    }
    &.data-utility {
      .ttl {
        color: #ed7d31;
        border-color: #ed7d31;
        background-image: url("../img/icon_product05.png");
      }
    }
    &.data-other {
      .ttl {
        color: #7f7f7f;
        border-color: #7f7f7f;
        background-image: url("../img/icon_product06.png");
      }
    }
  }
}

/* -- キーワードから探す -- */
#search-keyword {
  .search-area {
    padding: 20px 0;
    text-align: center;
    @include media(sp) {
      padding: 15px 10px;
    }
  }
  .form-control {
    @include media(sp) {
      display: block;
      width: 100%;
      margin-bottom: 5px;
      box-sizing: border-box;
    }
  }
  input[type="text"].form-control {
    width: 35%;
    @include media(sp) {
      width: 100%;
    }
  }
  .btn {
    width: 100px;
    height: 33px;
    @include font-size(1.5);
    color: #fff;
    background-color: $c-blue;
    @include media(sp) {
      width: 100%;
      height: 40px;
      margin-top: 10px;
    }
  }
}

/* -- 目的別カテゴリから探す -- */
#search-category {
  .menu-list {
    padding: 20px 15px;
    li {
      display: inline-block;
      width: 47.9%;
      vertical-align: top;
      @include media(sp) {
        display: block;
        width: auto;
      }
    }
  }
}

/* -- お問い合わせ方法 -- */
#inquiry-chat {
  .chat-box {
    padding: 20px 20px 0;
    @include media(sp) {
      padding: 15px;
    }
    border: 1px solid $c-border;
  }
  .num-list {
    li {
      float: left;
      @include media(sp) {
        float: none;
      }
      .txt {
        min-height: 48px;
      }
      .img {
        margin-top: 5px;
        text-align: center;
        @include media(sp) {
          margin-top: 10px;
          margin-left: -20px;
        }
        img {
          @include media(pcS) {
            height: 174px;
          }
          @include media(pc) {
            height: 258px;
          }
        }
      }
      &.num01 {
        width: 48%;
        @include media(sp) {
          width: auto;
        }
        .txt {
          @include media(pcS) {
            width: 90%;
          }
          @include media(pc) {
            width: 63%;
          }
        }
        .img {
          @include media(sp) {
            margin-top: 0;
          }
        }
      }
      &.num02 {
        width: 52%;
        @include media(sp) {
          width: auto;
        }
      }
    }
    img.visible-pc {
      @include media(pcS) {
        display: block;
      }
    }
    img.visible-sp {
      @include media(sp) {
        display: inline-block;
      }
    }
  }
}
.inquiry-list {
  overflow: hidden;
  text-align: center;
  li {
    float: left;
    width: 33.333%;
    margin: 25px 0;
    text-align: center;
    font-weight: bold;
    @include font-size(1.8);
    color: $c-blue;
    img {
      width: 37.145%;
    }
    p {
      display: inline-block;
      margin-top: 5px;
    }
  }
}
.notice-list {
  dt {
    font-weight: bold;
    color: $c-blue;
  }
  dd {
    position: relative;
    margin-top: 5px;
    padding-left: 1em;
    &:before {
      display: block;
      content: "・";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.prepare-box {
  padding: 20px;
  background-color: #ffffcd;
  @include media(sp) {
    padding: 15px;
  }
  .num-list {
    margin-top: 5px;
  }
}
.support-area {
  overflow: hidden;
  .box-left {
    float: left;
    width: 50%;
    @include media(sp) {
      float: none;
      width: auto;
    }
  }
  .box-right {
    float: left;
    width: 45%;
    padding-left: 5%;
    @include media(sp) {
      float: none;
      width: auto;
      padding-left: 0;
      margin-top: 15px;
    }
  }
  .txt-tel {
    @include font-size(2.2);
  }
}
.support-tbl {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 2px solid #000;
  background-color: #fff;
  th {
    padding: 0 5px;
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid #000;
  }
  td {
    width: 16.666%;
    padding: 2px 5px;
    line-height: 1.0;
    @include font-size(1.3);
    border-left: 2px solid;
    border-bottom: 2px solid #000;
    &.txt-center {
      border-left: 1px solid;
    }
    &.cell-description {
      border-left: 1px solid #aaa;
      border-bottom: 1px solid #aaa;
    }
    &.cell-description.border-none {
      border-bottom: none;
    }
    &.cell-yellow { background-color: #ffff00; }
    &.cell-blue { background-color: #00aff6; }
    &.cell-orange { background-color: #fdc300; }
    &.cell-red { background-color: #ff0000; }
    &.cell-gray { background-color: #7f817b; }
  }
}

/* -- 製品別カラー -- */
#data-recover {
  .ttl-product {
    background-color: #ff0000;
  }
  .product-box {
    .ttl {
      color: #ff0000;
      border-color: #ff0000;
      background-image: url("../img/icon_product01.png");
    }
  }
}
#data-terminator {
  .ttl-product {
    background-color: #0070c0;
  }
  .product-box {
    .ttl {
      color: #0070c0;
      border-color: #0070c0;
      background-image: url("../img/icon_product02.png");
    }
  }
}
#data-move {
  .ttl-product {
    background-color: #00b050;
  }
  .product-box {
    .ttl {
      color: #00b050;
      border-color: #00b050;
      background-image: url("../img/icon_product03.png");
    }
  }
}
#data-backup {
  .ttl-product {
    background-color: #002060;
  }
  .product-box {
    .ttl {
      color: #002060;
      border-color: #002060;
      background-image: url("../img/icon_product04.png");
    }
  }
}
#data-utility {
  .ttl-product {
    background-color: #ed7d31;
  }
  .product-box {
    .ttl {
      color: #ed7d31;
      border-color: #ed7d31;
      background-image: url("../img/icon_product05.png");
    }
  }
}
#data-other {
  .ttl-product {
    background-color: #7f7f7f;
  }
  .product-box {
    .ttl {
      color: #7f7f7f;
      border-color: #7f7f7f;
      background-image: url("../img/icon_product06.png");
    }
  }
}

/* -- Q&Aアンケート -- */
#qa-enquete {
  .bg-gray {
    text-align: center;
    padding: 20px 0;
  }
  .btn-link {
    display: inline-block;
    width: 220px;
    margin: 0 10px;
    @include media(sp) {
      width: 40vw;
      margin: 0 5px;
    }
  }
}

/* -- TOP -- */
#index {
  padding: 20px 0;
  .product-box {
    width: 32%;
    margin-bottom: 2%;
    @include media(pcS) {
      width: 49%;
    }
    @include media(sp) {
      width: auto;
      margin-bottom: 20px;
    }
  }
}


/* -- FAQ一覧 -- */
.faq-history-list {
  li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 1.5em;
    &:before {
      display: block;
      content: "Q.";
      position: absolute;
      top: 0;
      left: 0;
      font-weight: bold;
      font-size: 108%;
      color: $c-blue;
    }
    a {
      color: $c-text;
    }
  }
}
.faq-list {
  margin-top: 20px;
  padding: 0 20px;
  border-top: 1px solid $c-border;
  @include media(sp) {
    padding: 0 10px;
  }
  dt {
    min-height: 48px;
    margin-top: 20px;
    padding-left: 55px;
    font-weight: bold;
    background: transparent url("../img/icon_qa01.png") 0 4px no-repeat;
    background-size: 42px auto;
  }
  dd {
    min-height: 48px;
    margin-top: 10px;
    padding-left: 55px;
    background: transparent url("../img/icon_qa02.png") 0 4px no-repeat;
    background-size: 42px auto;
  }
}


/* -- FAQ詳細 -- */
.ttl-faq {
  min-height: 50px;
  margin-top: 20px;
  padding: 15px 10px 16px 80px;
  font-weight: bold;
  border: 1px solid $c-border;
  background: transparent url("../img/icon_qa01.png") 15px 14px no-repeat;
  background-size: 52px auto;
  @include media(sp) {
    min-height: 44px;
    padding: 10px;
    background: transparent url("../img/icon_qa01.png") 10px 12px no-repeat;
    background-size: 42px auto;
    &:before {
      display: block;
      content: '';
      float: left;
      width: 42px;
      height: 42px;
      margin: 2px 10px 0 0;
    }
  }
}
#faq-detail {
  min-height: 50px;
  margin-top: 20px;
  padding: 15px 10px 16px 80px;
  background: transparent url("../img/icon_qa02.png") 15px 14px no-repeat;
  background-size: 52px auto;
  @include media(sp) {
    min-height: 44px;
    padding: 10px;
    background: transparent url("../img/icon_qa02.png") 10px 12px no-repeat;
    background-size: 42px auto;
    &:before {
      display: block;
      content: '';
      float: left;
      width: 42px;
      height: 42px;
      margin: 2px 13px 0 0;
    }
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: bold;
  }
  & > *:first-child {
    margin-top: 0;
  }
  h1 {
    margin-top: 70px;
    @include font-size(2.2);
  }
  h2 {
    margin-top: 60px;
    @include font-size(2.0);
  }
  h3 {
    margin-top: 50px;
    @include font-size(1.8);
  }
  h4 {
    margin-top: 40px;
    @include font-size(1.7);
  }
  h5 {
    margin-top: 30px;
    @include font-size(1.6);
  }
  h6 {
    margin-top: 20px;
    @include font-size(1.5);
  }
  p {
    margin-top: 20px;
    & + p {
      margin-top: 10px;
    }
  }
  pre {
    margin-top: 20px;
    overflow: auto;
    white-space: pre;
  }
  em {
    font-style: italic;
  }
  hr {
    border: 1px solid $c-border;
  }
  blockquote > p {
    padding: 10px;
    background-color: $c-gray;
  }
  ul {
    list-style-type: disc;
    list-style-position: outside;
    margin: 20px 0 20px 22px;
  }
  ol {
    list-style-type: decimal;
    list-style-position: outside;
    margin: 20px 0 20px 22px;
  }
}



