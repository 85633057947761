@charset "utf-8";

//-----------------------------------
// ブレイクポイント設定
//-----------------------------------
$bp-sp: 759px;
$bp-pcS: 760px;
$bp-pc: 1050px;

@mixin media($media-width) {
  @if $media-width == sp {
    @media only screen and (max-width: $bp-sp) {
      @content;
    }
  }
  @else if $media-width == pcS {
    @media only screen and (min-width: $bp-pcS) and (max-width: 1049px) {
      @content;
    }
  }
  @else if $media-width == pc {
    @media print, screen and (min-width: $bp-pc) {
      @content;
    }
  }
}

//-----------------------------------
// 各デバイス表示
//-----------------------------------
.visible-pc {
  @include media(pc) {
    display: block;
  }
  @include media(pcS) {
    display: none;
  }
  @include media(sp) {
    display: none;
  }
}

.visible-pcS {
  @include media(pc) {
    display: none;
  }
  @include media(pcS) {
    display: block;
  }
  @include media(sp) {
    display: none;
  }
}

.visible-sp {
  @include media(pc) {
    display: none;
  }
  @include media(pcS) {
    display: none;
  }
  @include media(sp) {
    display: block;
  }
}


//-----------------------------------
// 変数
//フォントファミリー
$sans-serif: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;;
$serif: "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "HGS明朝E" , "ＭＳ Ｐ明朝" , "MS PMincho" ,"Roboto Slab" , Garamond , "Times New Roman" , serif;

//-----------------------------------
//レイアウト

//塗りの色
$c-blue: #005fae;
$c-gray: #f2f2f2;

//文字色
$c-text: #222;

//線色
$c-border: #e5e5e5;


//ベンダープレフィックス
$prefixList: -webkit-, -moz-, -ms-, -o-, null;

//calc
@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -o-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

//-----------------------------------
// 文字サイズrem対応
//-----------------------------------
@mixin font-size($s:1.4) {
  font-size:($s * 10) + px;
  font-size:$s + rem;
}

@mixin line-height($s:1.4) {
  line-height:($s * 10) + px;
  line-height:$s + rem;
}


//-----------------------------------
// その他汎用系クラス
//-----------------------------------
